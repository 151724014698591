import React from "react";
import { Modal, Button} from "react-bootstrap";
import axios from "../../utils/axios"

export default function EnableModal(props){

    const handleSubmit = async e => {
        let url = process.env.REACT_APP_URL_BACK + `/users/enable/${props.showId}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.patch(url, {}, config).then(function (response) {
            props.refresh()
            props.handleClose() 
        });
    }

    return(
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Habilitar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Estás seguro de que quieres habilitar el usuario?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Habilitar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}