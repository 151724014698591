import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import React from 'react';

  const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    preUser: null
  };
  
  const handlers = {
    INITIALIZE: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    },
    LOGIN: (state, action) => {
      const { user } = action.payload;
  
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    },
    PRELOGIN: (state, action) => {
      const { user } = action.payload;
      return {
        ...state,
        user: user,
      };
    },
    LOGOUT: (state) => ({
      ...state,
      isAuthenticated: false,
      user: null,
    }),
    REGISTER: (state, action) => {
      const { user } = action.payload;
  
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    },
  };
  
  //????
  const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;
  
  const AuthContext = createContext({
    ...initialState,
    method: "jwt",
    login: () => Promise.resolve(),
    prelogin: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
  });
  
  AuthProvider.propTypes = {
    children: PropTypes.node,
  };
  
  function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
  
    useEffect(() => {
      const initialize = async () => {
        try {
          const accessToken = window.localStorage.getItem("accessToken");
          const refreshToken = window.localStorage.getItem("refreshToken");
  
          if (accessToken && isValidToken(accessToken)) {
            setSession(accessToken, refreshToken);
  
            const user = {
              acccessToken: accessToken,
              refreshToken: refreshToken
            };
  
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
          else if(refreshToken && isValidToken(refreshToken))
          {
            axios.post("/auth/refresh", {
              "refresh_token": refreshToken,
            },{
              auth:{
                username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
                password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
              },
            }).then((rs) => {
              const accessToken = rs.data.access_token;
              setSession(accessToken, rs.data.refresh_token);
  
              const user = {
                acccessToken: accessToken,
                refreshToken: refreshToken
              };
  
              dispatch({
                type: "INITIALIZE",
                payload: {
                  isAuthenticated: true,
                  user,
                },
              });
  
            }).catch((error) => {
              console.error(error);
              dispatch({
                type: "INITIALIZE",
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
                }
            )
          }
          else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      };
  
      initialize();
    }, []);
  
    const login = async (user) => {
      setSession(user.acccessToken,user.refreshToken);
      dispatch({
        type: "LOGIN",
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    };
  
    const prelogin =  async (email,password) =>
    {
      const response = await axios.post("/auth/signin", {
        "email": email,
        "password": password,
      },
      {
        auth:{
          username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
          password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
        },
      });
  
      const { access_token, refresh_token} = await response.data;
      const user = {
        acccessToken: access_token,
        refreshToken: refresh_token
      };
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      login(user)
      dispatch({
        type: "PRELOGIN",
        payload: {
          user,
        },
      });
    }

    const register = async (email, password, username) => {
      const response = await axios.post("/auth/signup", {
        email,
        password,
        username
      });
      const { accessToken, user } = response.data;
  
      window.localStorage.setItem("accessToken", accessToken);
      dispatch({
        type: "REGISTER",
        payload: {
          user,
        },
      });
    };
  
    const logout = async () => {
      setSession(null, null);
      dispatch({ type: "LOGOUT" });
    };
  
    const resetPassword = () => {};
  
    const updateProfile = () => {};
  
    return (
      <AuthContext.Provider
        value={{
          ...state,
          method: "jwt",
          login,
          logout,
          prelogin,
          register,
          resetPassword,
          updateProfile,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
  
  export { AuthContext, AuthProvider };
  