import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from '../../../assets/img/avatar.png';
import Avatar from '../../common/Avatar';
import useAuth from '../../../hooks/useAuth'

function ProfileDropdown(props){
  const { user, logout} = useAuth();

  const handleNavItemClick = async () => {
    await logout(user)
  };
  
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={handleNavItemClick}>
            Cerrar Sesión
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
