import React, {useState, useEffect} from "react"
import { Navigate } from "react-router-dom";
import Table from "../components/common/Table";
import {Button} from "react-bootstrap";
import axios from "../utils/axios"
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import EnableModal from "../components/modals/EnableModal";

export default function User(){
    const [isAdmin, setAdmin] = useState(false)
    const [isLoaded, setLoaded] = useState(false)
    const [isTableLoaded, setTableLoaded] = useState(false)
    const [data, setData] = useState([])

    const columns = [
        {
            accessor: 'Usuario',
            Header: 'Usuario'
        },
        {
            accessor: 'Correo',
            Header: 'Correo'
        },
        {
            accessor: 'Tipo de Usuario',
            Header: 'Tipo de Usuario'
        },
        {
            accessor: 'Opciones',
            Header: 'Opciones'
        }
    ]

    //////CREATE USERS
    const [createShow, setCreateShow] = useState(false);

    //////UPDATE USERS
    const [updateShow, setUpdateShow] = useState(false);
    const [updateId, setUpdateId] = useState(0)

    //////DELETE USERS
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState(0)

    //////ENABLE USERS
    const [enableShow, setEnableShow] = useState(false)
    const [enableId, setEnableId] = useState(0)

    //HANDLE MODALS
    function handleClose(){
        setCreateShow(false)
        setUpdateShow(false)
        setUpdateId(0)
        setDeleteShow(false)
        setDeleteId(0)
        setEnableShow(false)
        setEnableId(0)
    }
    function handleCreateShow(){
        setCreateShow(true)
    };    
    const handleUpdateShow = event => {
        let id = event.currentTarget.id.substring(3);
        setUpdateId(id)
        setUpdateShow(true)
    }
    const handleDeleteShow = event => {
        let id = event.currentTarget.id.substring(3);
        setDeleteId(id)
        setDeleteShow(true)
    }
    const handleEnableShow = event => {
        let id = event.currentTarget.id.substring(3);
        setEnableId(id)
        setEnableShow(true)
    }

    function searchUsers(reload = false){
        //setTableLoaded(false)
        if(reload) setTableLoaded(false)
        let userUrl = process.env.REACT_APP_URL_BACK + `/users/all`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(userUrl, config).then((response) => {
            let users = response.data
            let uData = []
            for (const uIndex in users){
                let userIndex = users[uIndex]
                let admin = "Usuario Normal"
                if (userIndex.admin){
                    admin = "Usuario Administrador"
                }
                let userIndexData = {
                    "Id": userIndex.id,
                    "Usuario": userIndex.username,
                    "Correo": userIndex.email,
                    "Tipo de Usuario": admin,
                    "Opciones": <>
                        {userIndex.active ? 
                        <>
                            <Button id={"UP/" + userIndex.id.toString()} onClick={handleUpdateShow} size="sm" variant="info">Actualizar</Button>
                            <span className="space" style={{"paddingLeft":"2vh"}}></span>
                            <Button id={"DL/" + userIndex.id.toString()} onClick={handleDeleteShow} size="sm" variant="danger">Eliminar</Button>
                        </>
                        :
                        <Button id={"HA/" + userIndex.id.toString()} onClick={handleEnableShow} size="sm" variant="primary">Habilitar</Button>}
                    </> 
                }
                uData.push(userIndexData) 
            }
            setData(uData)
            setTableLoaded(true)
        })
    }

    useEffect(() => {
        let url = process.env.REACT_APP_URL_BACK + `/users/me`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then((response) => {
            let userData = response.data
            setAdmin(userData.admin && userData.active)
            setLoaded(true)
            searchUsers()
        })
      },[isTableLoaded])

    return (
        <>
        {isLoaded ? 
            <>
                {isAdmin ? 
                    <>
                        <div className='space' style={{"padding": "2vh"}}></div>
                        <Table loaded={isTableLoaded}
                                data={data}
                                columns={columns}
                                title={"Lista de Usuarios"}
                                tabIndex={5}
                                canCreate={true}
                                createOpt={"Crear Usuario"}
                                create={handleCreateShow}></Table>

                        <CreateModal show={createShow}
                                     handleClose={handleClose}
                                     refresh={searchUsers}/>

                        <UpdateModal show={updateShow}
                                     showId={updateId}
                                     refresh={searchUsers}
                                     handleClose={handleClose}/>

                        <DeleteModal show={deleteShow}
                                     showId={deleteId}
                                     refresh={searchUsers}
                                     handleClose={handleClose}/>

                        <EnableModal show={enableShow}
                                     showId={enableId}
                                     refresh={searchUsers}
                                     handleClose={handleClose}/>
                    </>
                :
                    <Navigate to={"/signin"} />}
            </>
        :
         <></>}    
        </>
    );

}