export const initRoutes = {
    label: 'Inicio',
    children: [
        {
            name: 'Dashboard',
            icon: 'fa-chart-pie',
            to: '/dashboard',
            active: true,
            color: "#808080"
        },
        {
            name: 'Análisis',
            icon: 'fa-chart-line',
            to: 'https://app.powerbi.com/singleSignOn',
            active: true,
            color: "#808080"
        },
        {
            name: 'Cuadratura Mensual',
            icon: 'fa-star',
            to: 'cuadratura',
            active: true,
            color: "#808080"
        }
    ]
}

export const tableRoutes = {
    label: 'Tablas',
    children: [
        {
            name: 'Valores Energía',
            icon: 'fa-dollar',
            to: '/energy',
            active: true,
            color: "#808080"
        },
        {
            name: 'Presupuesto',
            icon: 'fa-bolt',
            to: '/budget',
            active: true,
            color: "#808080"
        }
    ]
}

export const userRoutes = {
    label: 'Administración',
    children: [
        {
            name: 'Usuarios',
            icon: 'fa-user',
            to: '/users',
            active: true,
            color: "#808080"
        }
    ]
}
// eslint-disable-next-line
export default [initRoutes, tableRoutes, userRoutes]