import React from 'react';
import Flex from '../common/Flex';

const Terms = () => (
  <>
    <Flex className="mb-2">
      <h3>Terminos de Uso</h3>
    </Flex>
    Lorem ipsum dolor sit amet. Qui suscipit aliquam non minus iusto qui pariatur nemo hic voluptas beatae ut ullam dolor At minima ipsam cum alias voluptatem. Est quaerat vero et amet sunt ut iure dolorum. Qui illo temporibus aut eveniet repudiandae sit consequatur quasi et autem modi aut illum voluptatibus et neque dolores hic repellendus blanditiis!

    Et totam ipsa est dolore voluptatem nam unde eligendi. Cum dolorum voluptate ab quasi temporibus et internos tempora sed facere autem rem galisum velit. Vel nihil provident vel quia dolores eos voluptatem fugit sed illum quaerat. Ut reprehenderit quia et perspiciatis quia At optio atque.

    Et quod culpa a nostrum ipsa non rerum fugiat qui dolore quia rem adipisci quasi vel galisum veniam qui fugit provident. Cum consequuntur impedit est velit modi eos fugit perspiciatis et blanditiis vero. Sit omnis laboriosam aut consequatur vero est nihil exercitationem. Qui nisi voluptatem ea omnis sunt 33 sequi deleniti qui facilis voluptas.
  </>
);

export default Terms;