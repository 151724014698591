import React, { useState } from 'react';
import Flex from '../common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import barChart from '../../assets/img/illustrators/crm-bar-chart.png';
import lineChart from '../../assets/img/illustrators/crm-line-chart.png';
import GreetingsDate from './GreetingsDate';
import Switch from '../common/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Greetings(props) {

  const [value, setValue] = useState(false);

  function manageSwitch(){
    props.updateData(null, null, !value)
    setValue(!value)
  }

  return (
    <Card className="bg-100 shadow-none border mb-3">
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          <Col sm="auto">
            <Flex alignItems="center">
              <img src={barChart} width={90} alt="..." className="ms-n4" />
              <div>
                <h6 className="text-primary fs--1 mb-0">Bienvenidos a </h6>
                <h4 className="text-primary fw-bold mb-0">
                  Panel de
                  <span className="text-info fw-medium"> Monitoreo</span>
                </h4>
              </div>
              <img
                src={lineChart}
                width={140}
                alt="..."
                className="ms-n4 d-md-none d-lg-block"
              />
            </Flex>
          </Col>
          <Col md="auto" className="mb-3 mb-md-0">
            <Row className="g-3 gy-md-0 h-100 align-items-center">
              <Col xs="auto">
                <h6 className="text-700 mb-0 text-nowrap">Mostrar en: </h6>
              </Col>
              <Col md="auto">
                <GreetingsDate updateData={props.updateData} view={props.view}
                                size={"sm"}/>
              </Col>
              <Col md="auto">
                <h6 className="text-700 mb-0 text-nowrap">
                    <FontAwesomeIcon icon="fa-eye" style={{"paddingRight": "0.5vh"}}/>
                Ver todas las plantas </h6>
              </Col>
              <Col md="auto" style={{"marginBottom":"2vh"}}>
                <Switch isOn={value}
                        onColor="#27ab39"
                        handleToggle={manageSwitch}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Greetings;
