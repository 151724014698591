import React from 'react';
import Flex from '../common/Flex';
import LoginForm from '../../components/forms/LoginForm';

const Login = () => (
  <>
    <Flex justifyContent="between" alignItems="center" className="mb-2">
      <h5>Iniciar Sesión</h5>
    </Flex>
    <LoginForm />
  </>
);

export default Login;
