import React, {useState} from "react";
import { Modal, Button} from "react-bootstrap";
import axios from "../../utils/axios"
import AlertMessage from "../errors/alert";

export default function DeleteModal(props){
    const [showAlert, setShowAlert] = useState(false)
    const [showMessage, setShowMessage] = useState(false)

    const handleSubmit = async e => {
        let url = process.env.REACT_APP_URL_BACK + `/users/delete/${props.showId}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.delete(url, config).then(function (response) {
            props.refresh(true)
            closeModal() 
        }).catch(function (error) {
            setShowAlert(true)
            setShowMessage("El sistema no puede quedar sin administradores")
        });
    }

    function closeModal(){
        setShowAlert(false)
        props.handleClose()
    }

    return(
        <Modal show={props.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Estás seguro de que quieres eliminar el usuario?
                {showAlert ? 
                    <AlertMessage alert={"danger"} message={showMessage} />
                :
                    <></>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={handleSubmit}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}