import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

function getMyName(){
    let url = process.env.REACT_APP_URL_BACK + `/users/me`
    const config = {
        headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
    };
    axios.get(url,config).then((response) => {
        let userData = response.data
        localStorage.setItem('username', userData.username);
    })
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};

const setSession = (accessToken, refreshToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        getMyName()
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
        delete axios.defaults.headers.common.Authorization;
    }
};

export { isValidToken, setSession, verify, sign };
