import React, {useEffect, useState} from "react";
import { Modal, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import axios from "../../utils/axios"
import moment from "moment-timezone";
import Table from "../common/Table";

export default function DetailModal(props){
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)

    const columns = [
        {
            accessor: 'Resultado',
            Header: 'Resultado'
        },
        {
            accessor: 'Fecha',
            Header: 'Fecha y Hora'
        },
        {
            accessor: 'Dispositivo',
            Header: 'Dispositivo'
        },
        {
            accessor: 'Descripción',
            Header: 'Descripción'
        }
    ]

    function closeModal(){
        props.handleClose()
    }

    function setResult(code){
        if (code != 200){
            return <>
                <div style={{"width": "5vw", 
                "borderRadius": "1vw", 
                "backgroundColor": "rgb(184, 14, 14)",
                "color": "white",
                "textAlign": "center"}}>
                    <strong>ERROR ({code})</strong>
                </div>
            </>
        }
        return <>
            <div style={{"width": "5vw", 
            "borderRadius": "1vw", 
            "backgroundColor": "rgb(28, 187, 36)",
            "color": "white",
            "textAlign": "center"}}>
                <strong>OK ({code})</strong>
            </div>
        </>
    }

    function createTooltip(message){
        if (message == "unknown error")
            return (
                <Tooltip id="tooltip">
                    <strong>Objeto entregado por la API del fabricante es inválido o incompleto</strong>
                </Tooltip>
            );
        return (
            <Tooltip id="tooltip">
                <strong>{message}</strong>
            </Tooltip>
        );
    }

    function readDescription(code, msg, err = ""){
        if(msg != 'default'){
            if (code == 200)
                return msg
            return <OverlayTrigger placement="top" overlay={createTooltip(err)}>
                <div>{msg}</div>
            </OverlayTrigger>
        }
        if (code == 200){
            return "Dispositivo leido correctamente"
        }
        return <OverlayTrigger placement="top" overlay={createTooltip(err)}>
            <div>API No entrega datos</div>
                </OverlayTrigger>
    }

    function readHour(hour){
        let offset = moment().tz("America/Santiago").utcOffset()/60
        return moment(hour, "YYYY-MM-DDTHH:mm:ss").tz("America/Santiago")
                .add(offset, "hours").format("YYYY/MM/DD-HH:mm:ss")
    }

    function request(){
        setLoaded(false)
        let dateFromStr = moment(props.dateFrom).format("YYYYMMDD").toString()
        let dateToStr = moment(props.dateTo).format("YYYYMMDD").toString()
        let detailURL = process.env.REACT_APP_URL_BACK + `/bitacoraservicio/${props.showId}/${dateFromStr}/${dateToStr}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(detailURL, config).then((response) => {
            let data = []
            for(let rIndex = 0; rIndex < response.data.length; rIndex++){
                let responseIndex = response.data[rIndex]
                data.push({
                    "Resultado": setResult(responseIndex.status_code),
                    "Fecha": readHour(responseIndex.start_date),
                    "Dispositivo": responseIndex.device + "-" + responseIndex.name,
                    "Descripción": readDescription(responseIndex.status_code,
                                    responseIndex.summary, responseIndex.errorMessage)
                })
            }
            setData(data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        if (props.show) 
            request()
    }, [props.show])

    return(
        <Modal show={props.show} onHide={closeModal} size={data.length > 0 ? "xl" : "s"}>
            <Modal.Header closeButton>
                <Modal.Title>Detalle de la carga</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table loaded={loaded} 
                    data={data} 
                    columns={columns}
                    tabIndex={6} 
                    title="Resultados"
                    canCreate={false}
                />    
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}