import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../../context/context';
import React, { useContext } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProfileDropdown from './ProfileDropdown';


function TopNavRightSideNavItem(props){
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <span>{props.username}</span>
        <div className='space' style={{"paddingRight":"1vh"}}></div>
      </Nav.Item>     
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Cambiar a modo claro' : 'Cambiar a modo oscuro'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <ProfileDropdown/>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
