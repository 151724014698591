import React from "react"
import SearchForm from "../components/sections/SearchForm";
import axios from "axios";
import { useEffect, useState } from 'react';
import Table from "../components/common/Table";
import ExcelForm from "../components/sections/ExcelForm";
import formatNumberStr from "../methods/formatting";

export default function Energy(){
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [isAdmin, setAdmin] = useState(false)

    const columns = [
        {
            accessor: 'Mes',
            Header: 'Mes'
        },
        {
            accessor: 'Planta',
            Header: 'Planta'
        },
        {
            accessor: 'Compañía eléctrica',
            Header: 'Compañía eléctrica'
        },
        {
            accessor: '$ Energía (kW/h)',
            Header: '$ Energía (kW/h)'
        },
        {
            accessor: '$ Transporte (kW/h)',
            Header: '$ Transporte (kW/h)'
        },
        {
            accessor: '$ Cargo por servicio (kW/h)',
            Header: '$ Cargo por servicio (kW/h)'
        },
        {
            accessor: '$ Otros (kW/h)',
            Header: '$ Otros (kW/h)'
        },
        {
            accessor: '$ Total (kW/h)',
            Header: '$ Total (kW/h)'
        },
        {
            accessor: '$ Fijo',
            Header: '$ Fijo'
        },
    ]

    async function apiRequest(companyName = "FIMER", plantName="NULL", electric="NULL"){
        setLoaded(false)
        if(companyName==="") plantName = "FIMER"
        if(plantName==="") plantName = "NULL"
        if(electric==="") electric = "NULL"
        let url = process.env.REACT_APP_URL_BACK + 
                `/plant/energy/${companyName}/${plantName}/${electric}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then(async (response) => {
            let energy = response.data
            let dataResult = []
            for (const eIndex in energy){
                let energyIndex = energy[eIndex]
                let data = {
                    "Mes": energyIndex.date.substring(0, 7),
                    "Planta": energyIndex.name,
                    "Compañía eléctrica": energyIndex.company,
                    "$ Energía (kW/h)": formatNumberStr(energyIndex.energyPrice, 5),
                    "$ Transporte (kW/h)": formatNumberStr(energyIndex.transportPrice, 5),
                    '$ Cargo por servicio (kW/h)': formatNumberStr(energyIndex.chargePrice, 5),
                    "$ Otros (kW/h)": formatNumberStr(energyIndex.otherPrice,5),
                    '$ Total (kW/h)': formatNumberStr(energyIndex.total,5),
                    "$ Fijo": formatNumberStr(energyIndex.fixedPrice,5)
                }
                dataResult.push(data)
            }
            setData(dataResult)
            setLoaded(true) 
        }).catch(function (error) {
            setData([])
            setLoaded(true)})
    }

    useEffect(() => {
        let url = process.env.REACT_APP_URL_BACK + `/users/me`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then((response) => {
            let userData = response.data
            setAdmin(userData.admin)
        })
        apiRequest()
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='space' style={{"padding": "2vh"}}></div>
            <SearchForm loadData={apiRequest} isEnergy={true}/>
            <Table loaded={loaded} 
                   data={data} 
                   columns={columns} 
                   tabIndex={3}
                   title="Valores de energía"
            /> 
            {isAdmin ? <ExcelForm window={"energy"}
                                  updateData={apiRequest}/>:<></>}
            
        </>
    );
}