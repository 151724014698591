import AdvanceTableWrapper from './advance-table/AdvanceTableWrapper';
import AdvanceTable from './advance-table/AdvanceTable';
import AdvanceTableSearchBox from './advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from './advance-table/AdvanceTableFooter';
import Spinner from './Spinner';
import {Row, Col, Button} from "react-bootstrap";


export default function Table(props){
    return(
        <>
            {props.loaded ?
                    <>
                        {props.data ? 
                            <>
                                {props.data.length > 0 ? 
                                <div className='mb-3 card' style={{"padding": "2vh"}}>
                                    <AdvanceTableWrapper
                                    columns={props.columns}
                                    data={props.data}
                                    sortable
                                    pagination
                                    perPage={30}
                                    >
                                    {props.canCreate ? 
                                        <>
                                        <Row className="mb-3">
                                            <Col sm={8}>
                                                <h4>{props.title}</h4>
                                            </Col>  
                                            <Col sm={2} lg={2} md={{ offset: 2 }}>
                                                <Button variant="success" onClick={props.create}>{props.createOpt}</Button>
                                            </Col>  
                                        </Row>
                                        <Row className="flex-end-center mb-3">
                                            <Col xs="auto" sm={6} lg={4}>
                                                <AdvanceTableSearchBox table
                                                                    setFiltroTabla={props.setFiltroTabla}
                                                                    filtroTabla={props.filtroTabla}/>
                                            </Col>  
                                        </Row>
                                        </>
                                    : 
                                    <Row className="flex-end-center mb-3">
                                        <Col xs="auto" sm={5} lg={8}>
                                            <h4>{props.title}</h4>
                                        </Col>
                                        <Col xs="auto" sm={6} lg={4}>
                                            <AdvanceTableSearchBox table
                                                                setFiltroTabla={props.setFiltroTabla}
                                                                filtroTabla={props.filtroTabla}/>
                                        </Col>  
                                    </Row>}
                                    
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tabIndex={props.tabIndex}
                                        tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs--1 mb-0 overflow-hidden',
                                        }}
                                    />
                                    <div className="mt-3">
                                        <AdvanceTableFooter
                                        rowCount={props.data.length}
                                        table
                                        rowInfo
                                        navButtons
                                        rowsPerPageSelection
                                        />
                                    </div>
                                    </AdvanceTableWrapper>
                                </div> 
                                : 
                                <div className='mb-3 card' style={{"padding": "2vh", "alignItems": "center"}}>
                                    No hay datos que coinciden con la búsqueda
                                </div>}  
                            </>
                        : 
                        <div className='mb-3 card' style={{"padding": "2vh", "alignItems": "center"}}>
                            El tiempo de conexión fue agotado, reintente más tarde
                        </div>
                        }   
                   </>
                : 
                <div className='mb-3 card' style={{"padding": "2vh", "alignItems": "center"}}>
                    <Spinner></Spinner>
                </div> 
            } 
        </>
    )
}