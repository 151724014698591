import React, {useState} from "react";
import { Modal, Button} from "react-bootstrap";
import axios from "../../utils/axios"
import UserForm from "../forms/UserForm";

export default function CreateModal(props){

    const [FormData, setFormData] = useState({
        email: '',
        password: '',
        confirm: '',
        username: '',
        admin: "0",
        remember: false
    });

    const handleFieldChange = e => {
        setFormData({
          ...FormData,
          [e.target.name]: e.target.value
        });
      };

    const [showAlert, setShowAlert] = useState(false)
    const [showMessage, setShowMessage] = useState(false)

    function handleError(error){
        setShowAlert(true)
        if (error.response.data.message[0].includes("email"))
            if(FormData.email.length == 0){
                setShowMessage("Debe incluir un mail")
            }
            else{
                setShowMessage("Email en formato invalido")
            }
        else if (error.response.data.message[0].includes("username"))
            setShowMessage("Debe incluir un nombre")
        else if (error.response.data.message[0].includes("password"))
            setShowMessage("Debe incluir una contraseña")
        else
            setShowMessage("Usuario ya existe")
    }

    function closeModal(){
        setShowAlert(false)
        props.handleClose()
    }

    function restartForm(){
        setFormData({
            email: '',
            password: '',
            confirm: '',
            username: '',
            admin: "0",
        })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if(FormData.password == FormData.confirm){
            axios.post(process.env.REACT_APP_URL_BACK + "/auth/signup", {
                "username": FormData.username.trim(),
                "email": FormData.email.trim(),
                "password": FormData.password,
                "admin": (FormData.admin == "1")
            },{
                auth:{
                  username: process.env.REACT_APP_CLIENT_ID_JWT, 
                  password: process.env.REACT_APP_PASSWORD_CLIENT_JWT,
            }}).then(function (response) {
                if(response.data.statusCode != 409){
                    props.refresh(true)
                    restartForm()
                    setShowAlert(false)
                    closeModal()
                }
                else{
                    setShowAlert(true)
                    setShowMessage("Usuario ya existe")
                }   
            }).catch(function (error) {
                handleError(error)
            });
        }
        else{
            setShowAlert(true)
            setShowMessage("Verifique correctamente la contraseña")
        }
        
    }

    return(
        <Modal show={props.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserForm handleFieldChange={handleFieldChange}
                          formData={FormData}
                          showAlert={showAlert}
                          showMessage={showMessage}/>       
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cerrar
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                    Crear Usuario
                </Button>
            </Modal.Footer>
        </Modal>
    )
}