import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import GreetingsDate from '../dashboard/GreetingsDate';
import axios from 'axios';


function SearchForm(props){

    const options = [
        {
          label: "FIMER",
          value: "FIMER",
        },
        {
          label: "FRONIUS",
          value: "FRONIUS",
        },
        {
          label: "GROWATT",
          value: "GROWATT",
        },
        {
          label: "SMA",
          value: "SMA",
        },
        
      ];

    const date = new Date()
    const [startDate, setStartDate] = useState(formatDate(new Date(date.setDate(1))));
    const [endDate, setEndDate] = useState(formatDate(new Date()));

    const [plants, setPlants] = useState([
        {label: "Buscar todas",
        value: ""}])

    const [electric, setElectric] = useState([
        {label: "Buscar todas",
        value: ""}
    ])

    function formatDate(date){
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let dateFormated = year.toString()
        if (month < 10) dateFormated += "0"
        dateFormated += month.toString()
        if (day < 10) dateFormated += "0"
        dateFormated += day.toString()
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if(props.isEnergy){
            props.loadData(formData.company, formData.plant, formData.electric)
        }
        else{
            props.loadData(formData.company, formData.plant, 
                            startDate, endDate)
        }
    }

    function updateDate(start, end){
        setStartDate(start)
        setEndDate(end)
    }

    const [formData, setFormData] = useState({
        company: 'FIMER',
        plant: '',
        electric: '',
        remember: false
      });

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        setFormData({
            ...formData,
            plant: ''
        })
        let url = process.env.REACT_APP_URL_BACK + `/company/${formData.company}/name/plants`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url, config).then((response) => {
            let plants = response.data
            let list = [
                {
                    label: "Buscar todas",
                    value: ""
                }
            ]
            for(const pIndex in plants){
                let plantIndex = plants[pIndex]
                let dataIndex = {
                    label: plantIndex.name,
                    value: plantIndex.name
                }
                list.push(dataIndex)
            }
            setPlants(list)
        }).catch(() => {
            setPlants([
                {
                    label: "Buscar todas",
                    value: ""
                }
            ])
        })
    },[formData.company])

    useEffect(() => {
        let url = process.env.REACT_APP_URL_BACK + `/electric/all`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url, config).then((response) => {
            let electric = response.data
            let list = [
                {
                    label: "Buscar todas",
                    value: ""
                }
            ]
            for(const eIndex in electric){
                let electricIndex = electric[eIndex]
                let dataIndex = {
                    label: electricIndex.name,
                    value: electricIndex.name
                }
                list.push(dataIndex)
            }
            setElectric(list)
        })
    }, [])


    return(
        <>
            <div className='mb-3 card' style={{"padding": "2vh"}}>
                <div className="card-body pt-0">
                    <div className="tab-content">
                        <h4>Filtros de búsqueda</h4>
                        <div className='space' style={{"padding": "1vh"}}></div>
                        <Form onSubmit={handleSubmit}>
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                    <Col xs="auto" lg="3">
                                        <h5 className="text-600 mb-0 text-nowrap">Fabricante: </h5>
                                    </Col>
                                    <Col md="auto" lg="6">
                                        <Form.Group>
                                            <select className="form-select" name="company" value={formData.company} onChange={handleFieldChange}>
                                                {options.map((option) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <div className='space' style={{"padding": "1vh"}}></div>
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                    <Col xs="auto" lg="3">
                                        <h5 className="text-600 mb-0 text-nowrap">Planta: </h5>
                                    </Col>
                                    <Col md="auto" lg="6">
                                        <Form.Group>
                                            <select className="form-select" name="plant" value={formData.plant} onChange={handleFieldChange}>
                                                {plants.map((plant) => (
                                                    <option value={plant.value}>{plant.label}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>  
                            <div className='space' style={{"padding": "1vh"}}></div>
                            {props.isEnergy ? 
                                <Col md="auto" className="mb-3 mb-md-0">
                                    <Row className="g-3 gy-md-0 h-100 align-items-center">
                                        <Col xs="auto" lg="3">
                                            <h5 className="text-600 mb-0 text-nowrap">Compañía eléctrica: </h5>
                                        </Col>
                                        <Col md="auto" lg="6">
                                            <Form.Group>
                                                <select className="form-select" name="electric" value={formData.electric} onChange={handleFieldChange}>
                                                    {electric.map((el) => (
                                                        <option value={el.value}>{el.label}</option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            :
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                    <Col xs="auto" lg="3">
                                        <h5 className="text-600 mb-0 text-nowrap">Rango de fecha: </h5>
                                    </Col>
                                    <Col md="auto" lg="6" size="md">
                                        <GreetingsDate updateData={updateDate}/>
                                    </Col>
                                </Row>
                            </Col>}
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                    <Col xs="auto">
                                        <Form.Group>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mt-3 w-100"
                                                >
                                                Buscar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>          
                        </Form>
                    </div>
                </div>
            </div>      
        </>
    );
};

export default SearchForm