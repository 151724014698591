import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from '../components/navbar/top/NavbarTop';
import NavbarVertical from '../components/navbar/vertical/NavbarVertical.js';
import AppContext from '../context/context';
import Footer from '../components/footer/footer';
import classNames from 'classnames';
import axios from "../utils/axios"

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  const [username, setUserName] = useState("")

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    //setUserName(window.localStorage.getItem("username"));
    let url = process.env.REACT_APP_URL_BACK + `/users/me`
    const config = {
        headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
    };
    axios.get(url,config).then((response) => {
        let userData = response.data
        setUserName(userData.username)
    })
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
        <div className={classNames('content', { 'pb-0': isKanban })}>
            <NavbarTop username={username} />
            {/*------ Main Routes ------*/}
            <Outlet />
            {!isKanban && <Footer />}
        </div>
    </div>
  );
};

export default MainLayout;
