import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import React from "react"
import AuthSimpleLayout from "./layouts/AuthLayout";
import Login from "./components/sections/Login";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthGuard from "./guards/AuthGuard";
import useAuth from "./hooks/useAuth";
import MainLayout from './layouts/MainLayout';
import LoadingScreen from "./pages/Loading";
import Energy from "./pages/Energy";
import Budget from "./pages/Budget";
import User from "./pages/User";
import TemrsLayout from "./layouts/TermsLayout";
import Terms from "./components/sections/Terms";
import DataPrivacy from "./components/sections/DataPrivacy";
import Cuadratura from "./pages/Cuadratura";

function App() {
  const {isInitialized} = useAuth();

  return (
      <BrowserRouter>
        <Routes>
          <Route element={<AuthSimpleLayout />}>
            <Route path="/" element={< Login/>}></Route>
            <Route path="/signin" element={< Login/>}></Route>
          </Route>
          <Route element={<MainLayout />}>
            { isInitialized ? <Route path="/dashboard" element={   
              <AuthGuard>
                  <Dashboard/>
              </AuthGuard>
            }></Route> : <Route path="/dashboard" element={<LoadingScreen/>}></Route>}
            { isInitialized ? <Route path="/energy" element={   
              <AuthGuard>
                  <Energy/>
              </AuthGuard>
            }></Route> : <Route path="/energy" element={<LoadingScreen/>}></Route>}
            { isInitialized ? <Route path="/budget" element={   
              <AuthGuard>
                  <Budget/>
              </AuthGuard>
            }></Route> : <Route path="/budget" element={<LoadingScreen/>}></Route>}

            { isInitialized ? <Route path="/users" element={   
              <AuthGuard>
                  <User/>
              </AuthGuard>
            }></Route> : <Route path="/users" element={<LoadingScreen/>}></Route>}
            {isInitialized ? (
                <Route path="/cuadratura" element={
                  <AuthGuard>
                    <Cuadratura />
                  </AuthGuard>
                }></Route>
            ) : (
                <Route path="/cuadratura" element={<LoadingScreen />}></Route>
            )}
          </Route>
          <Route element={<TemrsLayout />}>
            <Route path="/terms" element={< Terms/>}></Route>
            <Route path="/data" element={< DataPrivacy/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter> 
  );
}

export default App;
