import Alert from 'react-bootstrap/Alert';
import React, { useState } from 'react';

function AlertMessage(props) {
  return (
    <>
        <div id="space" style={{paddingTop: "3vh"}}></div>
        <Alert key={props.alert} variant={props.alert} style={{height: "9vh"}}>
            <p>{props.message}</p>
        </Alert>
    </>
  );
}

export default AlertMessage;