import React from "react"
import SearchForm from "../components/sections/SearchForm";
import axios from "axios";
import { useEffect, useState } from 'react';
import Table from "../components/common/Table";
import ExcelForm from "../components/sections/ExcelForm";
import formatNumberStr from "../methods/formatting";

export default function Budget(){
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [isAdmin, setAdmin] = useState(false)

    function formatDate(date){
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let dateFormated = year.toString()
        if (month < 10) dateFormated += "0"
        dateFormated += month.toString()
        if (day < 10) dateFormated += "0"
        dateFormated += day.toString()
        return dateFormated
    }


    const date = new Date();
    const start = new Date(date.setDate(1));
    const end = new Date();
    const startDate = formatDate(start)
    const endDate = formatDate(end)

    const columns = [
        {
            accessor: 'Fecha',
            Header: 'Fecha'
        },
        {
            accessor: 'Planta',
            Header: 'Planta'
        },
        {
            accessor: 'Energía esperada (kW/h)',
            Header: 'Energía esperada (kW/h)'
        },
    ]

    async function apiRequest(companyName = "FIMER", plantName="NULL", start=startDate, end=endDate){
        setLoaded(false)
        if(companyName==="") companyName="FIMER"
        if(plantName==="") plantName="NULL"
        let url =  process.env.REACT_APP_URL_BACK + 
        `/plant/budget/${companyName}/${plantName}/${start}/${end}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then(async (response) => {
                let energy = response.data
                let dataResult = []
                for (const eIndex in energy){
                    let energyIndex = energy[eIndex]
                    let data = {
                        "Fecha": energyIndex.date.substring(0, 10),
                        "Planta": energyIndex.name,
                        "Energía esperada (kW/h)": formatNumberStr(energyIndex.sum,2),
                    }
                    dataResult.push(data)
                }
                setData(dataResult)
                setLoaded(true)
        }).catch(
            function (error) {
                setData([])
                setLoaded(true)
            }
        )
    }

    useEffect(() => {
        let url = process.env.REACT_APP_URL_BACK + `/users/me`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then((response) => {
            let userData = response.data
            setAdmin(userData.admin)
        })
        apiRequest()
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='space' style={{"padding": "2vh"}}></div>
            <SearchForm loadData={apiRequest} isEnergy={false}/>
            <Table loaded={loaded} 
                   data={data} 
                   columns={columns} 
                   tabIndex={3}
                   title="Valores esperados"

            /> 
            {isAdmin ? <ExcelForm window={"budget"}
                                updateData={apiRequest}/>:<></>}
        </>
    );
}