import React, {useEffect, useState} from "react";
import { Modal, Button} from "react-bootstrap";
import axios from "../../utils/axios"
import UserForm from "../forms/UserForm";

export default function UpdateModal(props){

    const [FormData, setFormData] = useState({
        email: '',
        password: '',
        username: '',
        admin: "0",
        confirm: '',
        id: 1,
        remember: false
    });

    const handleFieldChange = e => {
        setFormData({
          ...FormData,
          [e.target.name]: e.target.value
        });
      };

    const [showAlert, setShowAlert] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [defaultMail, setDefaultMail] = useState("")

    useEffect(() => {
        setLoaded(false)
        let userUrl = process.env.REACT_APP_URL_BACK + `/users/${props.showId}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(userUrl, config).then((response) => {
            let user = response.data
            let admin = "0"
            if(user.admin)
                admin = "1"
            setFormData({
                email: user.email,
                username: user.username,
                admin: admin,
                id: props.showId
            });
            setDefaultMail(user.email)
            setLoaded(true)
        }) 
    }, [props.showId])

    function handleError(error){
        setShowAlert(true)
        if (error.response.data.message[0].includes("email"))
            if(FormData.email.length == 0){
                setShowMessage("Debe incluir un mail")
            }
            else{
                setShowMessage("Email en formato invalido")
            }
        else if (error.response.data.message[0].includes("username"))
            setShowMessage("Debe incluir un nombre")
        else if (error.response.data.message[0].includes("password"))
            setShowMessage("Debe incluir una contraseña")
        else if (error.response.data.message.includes("admin"))
            setShowMessage(error.response.data.message)
        else
            setShowMessage("Usuario ya existe")
    }

    function closeModal(){
        setShowAlert(false)
        props.handleClose()
    }

    const handleSubmit = async e => {
        let pswd = FormData.password
        let cpswd = FormData.confirm
        if(!pswd) pswd=""
        if(!cpswd) cpswd=""
        if(FormData.email === "") FormData.email = defaultMail
        if(pswd === cpswd){
            let url = process.env.REACT_APP_URL_BACK + `/users/update/${FormData.id}`
            const config = {
                headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
            };
            axios.patch(url, {
                "username": FormData.username.trim(),
                "email": FormData.email.trim(),
                "password": FormData.password,
                "admin": (FormData.admin == "1")
            }, config).then(function (response) {
                if(!response.data.statusCode){
                    props.refresh(true)
                    closeModal()
                }
                else{
                    setShowAlert(true)    
                    if(response.data.statusCode == 409){
                        setShowMessage("Usuario ya existe")
                    }
                    else
                        setShowMessage("El sistema no puede quedar sin administradores")
                }   
            }).catch(function (error) {
                handleError(error)
            });
        }
        else{
            setShowAlert(true)
            setShowMessage("Verifique correctamente la contraseña")
        }
    }

    return(
        <Modal show={props.show && loaded} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Actualizar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserForm handleFieldChange={handleFieldChange}
                          formData={FormData}
                          showAlert={showAlert}
                          showMessage={showMessage}/>   
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cerrar
                </Button>
                <Button variant="info" onClick={handleSubmit}>
                    Actualizar Usuario
                </Button>
            </Modal.Footer>
        </Modal>
    )
}