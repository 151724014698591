import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form} from 'react-bootstrap';
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import AlertMessage from '../errors/alert';


const LoginForm = ({ hasLabel }) => {
  // State
  const {prelogin} = useAuth();
  const isMountedRef = useIsMountedRef();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [showAlert, setShowAlert] = useState(false)

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    try{
      await prelogin(formData.email, formData.password)
      //await login(user)
      window.location.replace("/dashboard");
    }
    catch(error){
      console.error(error);
      setFormData({email: "", password:""})
      setShowAlert(true)
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
          maxLength={100}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Contraseña' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          maxLength={50}
        />
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Iniciar Sesión
        </Button>
      </Form.Group>
      {showAlert ? 
        <AlertMessage alert={"danger"} message={"Credenciales Inválidas"} />
      :
        <p></p>}
      
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
