import axios from "axios";
import React, {useEffect, useState} from 'react';
import Greetings from '../components/dashboard/Greetings';
import "../components/common/status.css"
import Table from '../components/common/Table';
import {Button, OverlayTrigger, Tooltip, Modal, Form, Container, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HideModal from "../components/modals/HideModal";
import UnhideModal from "../components/modals/UnhideModal";
import formatNumberStr from "../methods/formatting";
import Big from 'big.js';

import 'moment-timezone';
import * as moment from "moment";
import DetailModal from "../components/modals/DetailModal";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {func} from "prop-types";

function Cuadratura() {
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [isAdmin, setAdmin] = useState(false)

    const [hideShow, setHideShow] = useState(false)
    const [hideId, setHideId] = useState(0)
    const [enableShow, setEnableShow] = useState(false)
    const [enableId, setEnableId] = useState(0)
    const [showAll, setShowAll] = useState(false)

    const [start, setStart] = useState(translateDate(new Date().setDate(1)))
    const [finish, setFinish] = useState(translateDate(new Date()))

    const [detailShow, setDetailShow] = useState(false);
    const [showDetailId, setShowDetailId] = useState(0)
    const [showModal, setShowModal] = useState(false);  // Nuevo estado para controlar la visibilidad del modal
    const [selectedPlant, setSelectedPlant] = useState(null); // Nuevo estado para almacenar la planta seleccionada
    const [generatedEnergy, setGeneratedEnergy] = useState(null); // Nuevo estado para almacenar la energía generada por la planta
    const [plantEnergy, setPlantEnergy] = useState(null); // Nuevo estado para almacenar la energía generada de esa planta
    const [plantName, setPlantName] = useState(null); // Nuevo estado para almacenar la energía generada de esa planta
    const [diferenciaEnergia, setDiferenciaEnergia] = useState('0.00');
    const [diferenciaEnergiaModal, setDiferenciaEnergiaModal] = useState('0.00');
    const [tolerance, setTolerance] = useState('0.00');
    const [filtroTabla, setFiltroTabla] = useState('');
    const [plantID, setPlantId] = useState(null);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [mensajeModal, setMensajeModal] = useState(false);
    const [initialDiferenciaEnergia, setInitialDiferenciaEnergia] = useState('');

    const [error, setError] = useState('');
    const [estadoSwitch, setEstadoSwitch] = useState(false);

    const handleShowModal = (id, energiaPlanta, energiaPortal, nombrePlanta) => {

        setSelectedPlant(id);
        setGeneratedEnergy(energiaPlanta);
        setPlantEnergy(energiaPortal);
        setPlantName(nombrePlanta)
        setPlantId(id)
        setShowModal(true);
    };

    const handleSwitchChange = (cerrarSwitch) => {
        if (!cerrarSwitch) {
            setEstadoSwitch(true);
        } else if (cerrarSwitch) {
            setEstadoSwitch(false);
        }
    };
    const handleSwitch = (estado) => {
        setMensajeModal(false);
        if (estado) {
            setEstadoSwitch(true)
            setConfirmModalShow(false)
        } else {
            setEstadoSwitch(false)
            setConfirmModalShow(false)
        }


    };

    function getTimestampFromDateStr(dateString) {
        // Extraer año, mes y día
        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(4, 6), 10) - 1; // Restar 1 porque los meses en JavaScript van de 0 a 11
        const day = parseInt(dateString.substring(6, 8), 10);

        // Crear un objeto Date con la fecha
        const dateObject = new Date(year, month, day);

        // Obtener el timestamp en segundos
        const timestamp = Math.floor((dateObject.getTime() / 1000) + 75599);

        return timestamp;
    }

    const validarCierre = async (diferenciaEnergia, plantId, cerrarPlanta) => {

        if(diferenciaEnergia === ''){
            diferenciaEnergia = "0.00"
        }
        let timestampDate = getTimestampFromDateStr(finish);
        const sumDifEnergy = parseFloat(diferenciaEnergia.replace(/\./g, '').replace(/,/g, '.')) + parseFloat(generatedEnergy.replace(/\./g, '').replace(/,/g, '.'));
        const sumDifEnergyTolerance = parseFloat(plantEnergy.replace(/\./g, '').replace(/,/g, '.') + tolerance).toFixed(2)
        const difSumas = parseFloat(sumDifEnergy) - parseFloat(plantEnergy.replace(/\./g, '').replace(/,/g, '.'))
        const resultadoRefactorizado = difSumas.toFixed(2).replace(/\./g, ',');

        setDiferenciaEnergiaModal(resultadoRefactorizado)

        if ((difSumas.toFixed(2) > tolerance || difSumas.toFixed(2) < -tolerance) && cerrarPlanta) {
            setConfirmModalShow(true);
        } else {
            if(cerrarPlanta){
                setMensajeModal(true)
                setConfirmModalShow(true);
            }else{
                const url = `${process.env.REACT_APP_URL_BACK}/plant/summary/energy/${diferenciaEnergia}/id/${plantId}/status/${cerrarPlanta}/timestamp/${timestampDate}`;

                // Realiza la solicitud POST con los datos necesarios
                const config = {
                    headers: {Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
                };
                //
                await axios.get(url, config)
                setEstadoSwitch(false)
                setShowModal(false)
                apiRequest()
            }
        }
    }

    const enviarDiferenciaEnergiaAPI = async (diferenciaEnergia, plantId, cerrarPlanta) => {
        let timestampDate = getTimestampFromDateStr(finish);


        if (plantId !== null && cerrarPlanta) {
            const url = `${process.env.REACT_APP_URL_BACK}/plant/summary/energy/${diferenciaEnergia}/id/${plantId}/status/${cerrarPlanta}/timestamp/${timestampDate}`;

            // Realiza la solicitud POST con los datos necesarios
            const config = {
                headers: {Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
            };
            //
            await axios.get(url, config)
            setEstadoSwitch(false)
        }
        apiRequest()
        setMensajeModal(false);
        setConfirmModalShow(false)
        setShowModal(false)
    }


    const handleCloseModal = () => {
        //setDiferenciaEnergia(initialDiferenciaEnergia);
        setError('');
        setEstadoSwitch(false);
        setShowModal(false);
    };
    const columns = [
        {
            accessor: 'v',
            Header: ' '
        },
        {
            accessor: 'Nombre',
            Header: 'Nombre'
        },
        {
            accessor: 'Compañia',
            Header: 'Compañía'
        },
        {
            accessor: 'Estado',
            Header: 'Estado'
        },
        {
            accessor: 'Última actualización',
            Header: 'Última actualización'
        },
        {
            accessor: 'EC',
            Header: 'EC'
        },
        {
            accessor: 'Energía portal EfeSolar (kW/h)',
            Header: 'Energía portal EfeSolar (kW/h)'
        },
        {
            accessor: 'Energía Portal Fabricante',
            Header: 'Energía Portal Fabricante'
        },
        {
            accessor: 'Cierre mes',
            Header: 'Cierre mes'
        }
    ];

    function timestampToDate(timestamp) {
        let date = new Date();
        if (timestamp) {
            date = new Date(timestamp * 1000)
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            let hour = date.getHours();
            let minutes = date.getMinutes();

            let currentDate = `${day}/${month}/${year}-${formatTime(hour)}:${formatTime(minutes)}`;
            return currentDate;
        }
        return "Planta sin info"
    }

    function formatTime(component) {
        if (parseInt(component) < 10) {
            return "0" + component
        }
        return component
    }

    function showDetailOfPlant(id = "0") {
        setShowDetailId(id)
        setDetailShow(true)
    }

    function createTooltip() {
        return (
            <Tooltip id="tooltip">
                <strong>Ver detalle de la carga</strong>
            </Tooltip>
        );
    }

    function getStatusColor(status, isEc = false, id = "0") {
        if (status === 3) {
            if (isEc) {
                return <>
                    <OverlayTrigger placement="top" overlay={createTooltip()}>
                        <div onClick={() => {
                            showDetailOfPlant(id)
                        }}>
                            <span style={{"display": "none"}}>1</span>
                            <span className="failing-dot"></span>
                        </div>
                    </OverlayTrigger>
                </>
            }
            return <>
                <div>
                    <span style={{"display": "none"}}>1</span>
                    <span className="failing-dot"></span>
                </div>
            </>
        } else if (status === 2) {
            if (isEc) {
                return <>
                    <OverlayTrigger placement="top" overlay={createTooltip()}>
                        <div onClick={() => {
                            showDetailOfPlant(id)
                        }}>
                            <span style={{"display": "none"}}>3</span>
                            <span className="good-dot"></span>
                        </div>
                    </OverlayTrigger>
                </>
            }
            return <>
                <div>
                    <span style={{"display": "none"}}>3</span>
                    <span className="good-dot"></span>
                </div>
            </>
        } else if (status === 0) {
            if (isEc) {
                return <>
                    <OverlayTrigger placement="top" overlay={createTooltip()}>
                        <div onClick={() => {
                            showDetailOfPlant(id)
                        }}>
                            <span style={{"display": "none"}}>1</span>
                            <span className="danger-dot"></span>
                        </div>
                    </OverlayTrigger>
                </>
            }
            return <>
                <div>
                    <span style={{"display": "none"}}>0</span>
                    <span className="danger-dot"></span>
                </div>
            </>
        }
        if (isEc) {
            return <>
                <OverlayTrigger placement="top" overlay={createTooltip()}>
                    <div onClick={() => {
                        showDetailOfPlant(id)
                    }}>
                        <span style={{"display": "none"}}>2</span>
                        <span className="ewarning-dot"></span>
                    </div>
                </OverlayTrigger>
            </>
        }
        return <>
            <div>
                <span style={{"display": "none"}}>2</span>
                <span className="ewarning-dot"></span>
            </div>
        </>
    }

    function getECStatus(charge, lastDate, id) {
        if (lastDate == "Planta sin info") {
            return getStatusColor(0, true, id)
        }
        let currentMoment = moment().tz("America/Santiago").unix()
        let dateTimestamp = moment(charge, "YYYY-MM-DD HH:mm:ss").tz("America/Santiago").unix()
        const diffTime = currentMoment - dateTimestamp;
        const difference = diffTime / (60 * 60);
        if (difference >= 24 || isNaN(difference)) {
            return getStatusColor(0, true, id)
        }
        if (difference >= 6) {
            return getStatusColor(3, true, id)
        }
        if (difference >= 2) {
            return getStatusColor(1, true, id)
        }
        return getStatusColor(2, true, id)
    }

    function getCompletedColor(completed, type = "?") {
        if (type == "%") {
            if (completed < 85) {
                return <span style={{"color": "red"}}>{formatNumberStr(completed, 2)}</span>
            }
            if (completed < 95) {
                return <span style={{"color": "rgb(255, 192, 0)"}}>{formatNumberStr(completed, 2)}</span>
            }
            return <span style={{"color": "green"}}>{formatNumberStr(completed, 2)}</span>
        } else if (type == "N") {
            if (completed < 0) {
                return <span style={{"color": "red"}}>{formatNumberStr(completed, 0)}</span>
            }
            return formatNumberStr(completed, 0, true)
        }
        return completed
    }

    function translateDate(dateN) {
        let date = new Date(dateN)
        if (date.getMonth() < 9 && date.getDate() < 10) {
            return date.getFullYear().toString() + "0" + (date.getMonth() + 1).toString() + "0" + date.getDate().toString()
        } else if (date.getMonth() < 9) {
            return date.getFullYear().toString() + "0" + (date.getMonth() + 1).toString() + date.getDate().toString()
        } else if (date.getDate() < 10) {
            return date.getFullYear().toString() + (date.getMonth() + 1).toString() + "0" + date.getDate().toString()
        }
        return date.getFullYear().toString() + (date.getMonth() + 1).toString() + date.getDate().toString()
    }

    const handleHide = event => {
        let id = event.currentTarget.id.substring(3);
        setHideId(id)
        setHideShow(true)
    }

    const handleEnable = event => {
        let id = event.currentTarget.id.substring(3);
        setEnableId(id)
        setEnableShow(true)
    }

    function handleClose() {
        setHideShow(false)
        setHideId(0)
        setEnableShow(false)
        setEnableId(0)
    }

    function hiddenButton(hidden, id) {
        if (hidden) {
            return <Button id={"HB/" + id} onClick={handleEnable} size="sm" variant="danger">
                <FontAwesomeIcon icon="fa-eye"/>
            </Button>
        }
        return <Button id={"HB/" + id} onClick={handleHide} size="sm" variant="primary">
            <FontAwesomeIcon icon="fa-eye"/>
        </Button>
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}${month}${day}`;
    }

    function getFormattedDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
        const day = '01';

        return `${year}${month}${day}`;
    }

    function energyButton(statusEnergy, id, energiaPlanta, energiaPortal, nombrePlanta, startDateCalendar) {
        const currentDate = getFormattedDate();

        const currentDateMoment = moment(currentDate, "YYYY-MM-DD");
        const startDateCalendarMoment = moment(startDateCalendar, "YYYY-MM-DD");
        const diffInMonths = currentDateMoment.diff(startDateCalendarMoment, 'months');

        if(diffInMonths >= 2){
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faCheck} style={{color: 'green', fontSize: '1.5em'}}/>
                </div>
            );
        }
        if (currentDate === startDateCalendar) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    </div>
                </div>
            );
        }
        if (!statusEnergy) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button variant="primary" size="sm"
                            onClick={() => handleShowModal(id, energiaPlanta, energiaPortal, nombrePlanta, startDateCalendar)}>
                        Cuadrar Planta
                    </Button>
                </div>
            );
        } else if (statusEnergy) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faCheck} style={{color: 'green', fontSize: '1.5em'}}/>
                </div>
            );
        }
    }


    function apiRequest(startDate = start, endDate = finish, all = showAll) {
        setShowAll(all);
        setLoaded(false);
        if(startDate == null || endDate == null){
            startDate = start
            endDate = finish
        }
        let plantIdsSet = new Set(); // Usamos un conjunto para asegurarnos de IDs únicos
        setStart(startDate);
        setFinish(endDate);
        const url = process.env.REACT_APP_URL_BACK + `/plant/cuadratura/start/${startDate}/end/${endDate}`;
        const config = {
            headers: {Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`},
        };

        axios.get(url, config)
            .then((response) => {
                let tableData = response.data;
                let dataResult = [];
                let offset = moment().tz("America/Santiago").utcOffset() / 60;

                for (const tIndex in tableData) {
                    let tableIndex = tableData[tIndex];
                    let energyPrice = Math.round(tableIndex.generatedprice);
                    let expectedPrice = Math.round(tableIndex.expectedprice);
                    let differencePrice = energyPrice - expectedPrice;
                    let lastDate = timestampToDate(tableIndex.last);
                    let lastChargeDate = moment(tableIndex.last_charge).tz("America/Santiago").format("YYYY-MM-DD HH:mm:ss");

                    let dataIndex = {
                        "v": hiddenButton(tableIndex.hidden, tableIndex.id),
                        "Nombre": tableIndex.plantname,
                        "Compañia": tableIndex.company,
                        "Estado": getStatusColor(tableIndex.status),
                        "Última actualización": lastDate,
                        "EC": getStatusColor(tableIndex.ec_status, true, tableIndex.id),
                        "Energía Portal EfeSolar (kW/h)": formatNumberStr(tableIndex.energia, 2),
                        "Energía Portal Fabricante (kW/h)": formatNumberStr(tableIndex.monthly_energy, 2),
                        "Cierre mes": energyButton(tableIndex.status_plant, tableIndex.id, formatNumberStr(tableIndex.energia, 2), formatNumberStr(tableIndex.monthly_energy, 2), tableIndex.plantname, startDate),
                    };

                    if (all || (!all && !tableIndex.hidden)) {
                        dataResult.push(dataIndex);
                    }
                }

                if (startDate == null || endDate == null) {
                    startDate = start;
                    endDate = finish;
                }

                setData(dataResult);
                setLoaded(true);
            })
            .catch((error) => {
                setData([]);
                setLoaded(true);
            });

    }

    useEffect(() => {
        apiRequest()
    }, []);
    useEffect(() => {

        if (generatedEnergy !== null && plantEnergy !== null) {
            const urlTolerance = `${process.env.REACT_APP_URL_BACK}/plant/summary/name/ENERGY_TOLERANCE`;
            const config = {
                headers: {Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
            };

            axios.get(urlTolerance, config).then((response) => {
                let energyTolerance = response.data / 1000
                setTolerance(Math.round(energyTolerance))
            })

            const energiaPlantaSinPuntos = generatedEnergy.replace(/\./g, '');
            const energiaPortalSinPuntos = plantEnergy.replace(/\./g, '');

            const energiaPlanta = energiaPlantaSinPuntos.replace(/,/g, '.');
            const energiaPortal = energiaPortalSinPuntos.replace(/,/g, '.');

            const diferenciaEnergia = parseFloat(energiaPortal) - parseFloat(energiaPlanta);
            const resultadoFormateado = diferenciaEnergia.toFixed(2).replace('.', '');

            //Extraer los últimos dos dígitos y guardarlos en una nueva variable
            const ultimosDosDigitos = resultadoFormateado.toString().slice(-2);

            // Guardar el resto de números en otra variable
            const restoDeNumeros = resultadoFormateado.toString().slice(0, -2);

            // Unir ambos resultados con una coma en el medio
            const resultadoFinal = restoDeNumeros + ',' + ultimosDosDigitos;

            setDiferenciaEnergia(resultadoFinal.toString());
        }
        let url = process.env.REACT_APP_URL_BACK + `/users/me`
        const config = {
            headers: {Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url, config).then((response) => {
            let userData = response.data
            setAdmin(userData.admin)
        })
        // eslint-disable-next-line
    }, [generatedEnergy, plantEnergy])

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Validar si solo contiene números, puntos, comas y el carácter "-" al inicio
        const validInputRegex = /^-?[0-9.,]*$/;

        if (validInputRegex.test(inputValue)) {
            // Validar el formato específico: 10 dígitos, una coma y dos dígitos después de la coma
            const formatRegex = /^-?\d{0,7}(,\d{0,2})?$/;

            if (formatRegex.test(inputValue) || inputValue === '') {
                setError('');
                // Actualizar el estado con el valor ingresado sin formato
                setDiferenciaEnergia(inputValue);
            } else {
                setError('Formato incorrecto. Debe tener 7 dígitos, una coma y dos dígitos después de la coma.');
            }
        } else {
            setError('Sólo números permitidos, y el signo "-" solo al inicio');
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {error}
        </Tooltip>
    );
    const formatNumber = (value) => {
        // Convertir a número y formatear con separadores de miles y decimales
        const numberValue = parseFloat(value.replace(/[^\d.-]/g, '')); // Eliminar caracteres no numéricos

        // Verificar si ya tiene decimales
        const hasDecimal = value.includes(',');

        // Formatear con separadores de miles y decimales, y ajustar el número decimal
        const formattedValue = numberValue.toLocaleString('es-ES', {
            minimumFractionDigits: hasDecimal ? 2 : 0,
            maximumFractionDigits: 2,
        });

        // Ajustar el número decimal si es diferente del valor original
        if (hasDecimal && formattedValue !== value) {
            const [, decimalPart] = value.split(',');
            return formattedValue.replace(/00$/, decimalPart);
        }

        return formattedValue;
    };
    const shouldDisableButtons = !!error
    function formatFecha(fechaString) {
        // Extraer año, mes y día de la cadena
        const año = fechaString.slice(0, 4);
        const mes = fechaString.slice(4, 6);

        // Mapear el número del mes a su nombre
        const nombresMeses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        const nombreMes = nombresMeses[parseInt(mes, 10) - 1];

        // Formatear la fecha en el nuevo formato
        const fechaFormateada = `${año} / ${nombreMes}`;

        return fechaFormateada;
    }
    function validaNumericos(event) {
        if(event.charCode >= 48 && event.charCode <= 57){
            return true;
        }
        return false;
    }
    return (
        <>
            <div className='space' style={{"padding": "3vh"}}></div>
            <Greetings updateData={apiRequest} view={1}/>
            <DetailModal show={detailShow} showId={showDetailId}
                         handleClose={() => setDetailShow(false)}
                         dateFrom={start} dateTo={finish}/>

            <Table
                setFiltroTabla={setFiltroTabla}
                filtroTabla={filtroTabla}
                loaded={loaded}
                data={data}
                columns={[
                    {
                        accessor: 'v',
                        Header: ' ',
                    },
                    {
                        accessor: 'Nombre',
                        Header: 'Nombre',
                    },
                    {
                        accessor: 'Compañia',
                        Header: 'Compañía',
                    },
                    {
                        accessor: 'Estado',
                        Header: 'Estado',
                    },
                    {
                        accessor: 'Última actualización',
                        Header: 'Última actualización',
                    },
                    {
                        accessor: 'EC',
                        Header: 'EC',
                    },
                    {
                        accessor: 'Energía Portal EfeSolar (kW/h)',
                        Header: 'Energía Portal EfeSolar (kW/h)',
                    },
                    {
                        accessor: 'Energía Portal Fabricante (kW/h)',
                        Header: 'Energía Portal Fabricante (kW/h)',
                    },
                    {
                        accessor: 'Cierre mes',
                        Header: 'Cierre mes',

                    },
                ]}
                tabIndex={4}
                title="Vista general"
                canCreate={false}
            />
            <HideModal show={hideShow}
                       showId={hideId}
                       refresh={apiRequest}
                       handleClose={handleClose}/>
            <UnhideModal show={enableShow}
                         showId={enableId}
                         refresh={apiRequest}
                         handleClose={handleClose}/>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <Modal.Title>
                                    <span style={{fontSize: '20px', fontWeight: 'bold'}}>{plantName}</span>
                                </Modal.Title>
                            </Col>
                            <Col md={6}>
                                <Modal.Title>
                                    <span style={{fontSize: '20px', fontWeight: 'bold'}}>{formatFecha(start)}</span>
                                </Modal.Title>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    {selectedPlant && (
                        <div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <Form.Group className="align-items-center">
                                            <Form.Label><strong>Energía Portal Fabricante (kW/h):</strong></Form.Label>
                                        </Form.Group>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        <Form.Group className="align-items-center">
                                            <Form.Label style={{marginLeft: '10px'}}>{plantEnergy}</Form.Label>
                                        </Form.Group>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <Form.Group className="align-items-center">
                                            <Form.Label><strong>Energía Portal EfeSolar (kW/h):</strong></Form.Label>
                                        </Form.Group>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        <Form.Group className="align-items-center">
                                            <Form.Label style={{marginLeft: '10px'}}>{generatedEnergy}</Form.Label>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Group className="align-items-center">
                                            <Form.Label><strong>Diferencia de Energía (kW/h):</strong></Form.Label>
                                        </Form.Group>
                                    </td>
                                    <td>

                                        <Form.Group className="align-items-right">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip}
                                                show={!!error}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    value={diferenciaEnergia}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setInitialDiferenciaEnergia(diferenciaEnergia)}
                                                    onKeyPress={(e) => {
                                                        // Permite números, puntos, comas y el signo de menos ("-")
                                                        if (!/[0-9.,-]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    style={{
                                                        width: '60%',
                                                        height: '10%',
                                                        marginLeft: 'auto',
                                                        marginRight: 0,
                                                        borderColor: error ? 'red' : ''
                                                    }}
                                                />
                                            </OverlayTrigger>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Label><strong>Cerrar Estado de Planta:</strong></Form.Label>
                                    </td>
                                    <td>
                                        <Form.Group className="align-items-right">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                style={{width: "30px", marginLeft: "auto", marginRight: 0}}
                                                defaultChecked={false}
                                                checked={estadoSwitch}
                                                onChange={() => handleSwitchChange(estadoSwitch)}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={shouldDisableButtons} className="mr-auto" onClick={() => {
                        validarCierre(diferenciaEnergia, plantID, estadoSwitch);
                    }}>
                        Confirmar y Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={confirmModalShow} onHide={() => setConfirmModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirmación
                        <p style={{ fontSize: '14px'}}>
                            Tolerancia de cierre de planta {tolerance} (kW/h)
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mensajeModal ? (
                        // Mostrar mensaje cuando mensajeModal es true
                        <p>Vas a cerrar la planta <span style={{ color: 'black' }}>{plantName}</span></p>
                    ) : (
                        // Mostrar el mensaje predeterminado cuando mensajeModal es false
                        <p>
                            Vas a cerrar la planta <span style={{ color: 'black' }}>{plantName}</span> con una diferencia de
                            energía de <span style={{ color: 'black' }}>{diferenciaEnergiaModal}</span> (kW/h)
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            onClick={() => enviarDiferenciaEnergiaAPI(diferenciaEnergia, plantID, estadoSwitch)}>
                        Confirmar
                    </Button>
                    <Button variant="secondary" onClick={() => handleSwitch(estadoSwitch)}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default Cuadratura