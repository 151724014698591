function formatNumberStr(number, decimals){
    let numbStr = formatNumber(number, decimals).toString()
    numbStr = numbStr.replace('.',',')
    let indexOfComa = numbStr.indexOf(',')
    let indexOfMinus = numbStr.indexOf('-')
    let signPart = ""
    let integerPart = numbStr
    let decimalPart = ""
    if (indexOfComa !== -1){
        decimalPart = numbStr.slice(numbStr.indexOf(','))
        if (indexOfMinus === -1)
            integerPart = numbStr.slice(0, numbStr.indexOf(','))
        else{
            integerPart = numbStr.slice(1, numbStr.indexOf(','))
            signPart = "-"
        } 
        decimalPart = decimalPart.replace(/0+$/, '');
        if (decimalPart == ',') decimalPart = ''
    } 
    else{
        if (indexOfMinus === -1)
            integerPart = numbStr.slice(0)
        else{
            integerPart = numbStr.slice(1)
            signPart = "-"
        }
    }
    let cant_miles = Math.floor((integerPart.length - 1)/3)
    let points_used = 0
    for(let index = 0; index < cant_miles; index++){
        let point_index = integerPart.length - points_used - 3*(index + 1)
        integerPart = insertStringAtIndex(integerPart, '.', point_index)
        points_used++
    }
    return signPart + integerPart + decimalPart
}

function formatNumber(number, decimals){
    let power10 = 10 ** decimals
    return ((parseFloat(number) * power10)/power10).toFixed(decimals)
}

function insertStringAtIndex(str, substr, index){
    return str.slice(0, index) + substr + str.slice(index);
}

export default formatNumberStr