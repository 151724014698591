import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-datepicker/dist/react-datepicker.css'
import es from "date-fns/locale/es";
import { addMonths, endOfMonth } from 'date-fns'; // Importa las funciones necesarias de date-fns

registerLocale("es", es);

const GreetingsDateInput = forwardRef(({ value, onClick, size }, ref) => (
  <div className="position-relative">
    <Form.Control
      size={size}
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={e => {}}
    />
    <FontAwesomeIcon
      icon="calendar-alt"
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

function formatDate(date){
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let dateFormated = year.toString()
  if (month < 10) dateFormated += "0"
  dateFormated += month.toString()
  if (day < 10) dateFormated += "0"
  dateFormated += day.toString()
  return dateFormated
}

function GreetingsDate(props) {
  const date = new Date();
  const [startDate, setStartDate] = useState(date.setDate(1));
  const [endDate, setEndDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const currentDate = new Date();
    const lastDayOfMonth = endOfMonth(currentDate)
  const onChange = dates => {
     if(props.view === 1){

         const start = dates;
         const end = new Date(dates)


         if(start != null && end != null){
             end.setMonth(end.getMonth() + 1);
             end.setDate(end.getDate() - 1);

             let startDate = formatDate(start)
             let endDate = formatDate(end);

             props.updateData(startDate, endDate)
         }

         setStartDate(start);
         setEndDate(end);
     }else{
         const [start, end] = dates;
         if(start != null && end != null){
             let startDate = formatDate(start)
             let endDate = formatDate(end)

             props.updateData(startDate, endDate)

         }
         setStartDate(start);
         setEndDate(end);
     }


  };
  return (
      <>
          {props.view === 1 ? (
              <DatePicker
                  locale="es"
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  customInput={<GreetingsDateInput size={props.size} />}
                  maxDate={lastDayOfMonth}
              />
          ) : (
              <DatePicker
                  locale="es"
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  formatWeekDay={day => day.slice(0, 3)}
                  endDate={endDate}
                  selectsRange
                  dateFormat="MMM dd"
                  customInput={<GreetingsDateInput size={props.size} />}
              />
          )}
      </>
  );
};

GreetingsDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default GreetingsDate;
