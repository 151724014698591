import React, {useState} from "react";
import { Modal, Button} from "react-bootstrap";
import axios from "../../utils/axios"

export default function UnhideModal(props){

    const handleSubmit = async e => {
        let url = process.env.REACT_APP_URL_BACK + `/plant/unhide/${props.showId}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.patch(url, {}, config).then(function (response) {
            props.refresh()
            closeModal() 
        })
    }

    function closeModal(){
        props.handleClose()
    }

    return(
        <Modal show={props.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Mostrar Planta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Estás seguro de que quieres mostrar la planta?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cerrar
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                    Mostrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}