import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import AlertMessage from '../errors/alert';
import axios from 'axios';
import Spinner from '../common/Spinner';

function ExcelForm(props){ 

    const handleSubmit = async e => {
        e.preventDefault();
        setFileUploading(true)
        let allowedFormat = ".xlsx"
        let allowedSizeKB = 10 * 1024
        setAlertType("danger")
        if(formData.file == [] || !formData.filename){
            setShowAlert(true)
            setFileUploading(false)
            setShowMessage(`Debe incluir un archivo antes de enviar`)
        }
        else{
            let fileFormat = formData.file.name.slice(-5)
            let fileSize = formData.file.size
            if (fileFormat !== allowedFormat){
                setShowAlert(true)
                setFileUploading(false)
                setShowMessage(`Solo se aceptan archivos ${allowedFormat}`)
            } 
            else if (fileSize/1024 > allowedSizeKB){
                setShowAlert(true)
                setFileUploading(false)
                setShowMessage(`El tamaño maximo permitido es ${allowedSizeKB} KB`)
            }
            else{
                let url = process.env.REACT_APP_URL_BACK + "/files/uploadEnergy"
                if(props.window != "energy"){
                    url = process.env.REACT_APP_URL_BACK + "/files/uploadBudget"
                }
                const config = {
                    headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
                };
                const data = new FormData()
                data.append('file', formData.file)
                setFormData({
                    file: [],
                    filename: ""
                });
                axios.post(url, data, config).then((response) => {
                    setFileUploading(false)
                    setShowAlert(true)
                    setAlertType("success")
                    setShowMessage("Los datos se han subido correctamente")
                    props.updateData()
                }).catch((error) => {
                    setFileUploading(false)
                    setShowAlert(true)
                    setAlertType("danger")
                    setShowMessage(error.response.data.message)
                })
            }  
        }        
    }

    const [formData, setFormData] = useState({
        file: [],
        filename: null
      });
    
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState("danger")
    const [showMessage, setShowMessage] = useState("")
    const [fileUploading, setFileUploading] = useState(false)

    const handleFieldChange = e => {
        var files = e.target.files
        setFormData({
            file: files[0],
            filename: e.target.value
        });
    };

    return(
        <>
            <div className='mb-3 card' style={{"padding": "2vh"}}>
                <div className="card-body pt-0">
                    <div className="tab-content">
                        <div className='space' style={{"padding": "1vh"}}></div>
                        <h4>Agregar datos vía excel</h4>
                        <Form onSubmit={handleSubmit}>
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                    <Col md="auto" lg="10">
                                        <Form.Group>
                                            <Form.Control
                                                name="file"
                                                onChange={handleFieldChange}
                                                value={formData.filename}
                                                type="file"
                                                maxLength={100}
                                                disabled={fileUploading}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Group>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mt-3 w-100"
                                                style={{"marginBottom": "2.7vh"}}
                                                disabled={fileUploading}
                                                >
                                                Enviar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="auto">
                                        {fileUploading ?
                                            <Spinner/> 
                                        : <></>}
                                    </Col>
                                </Row>
                            </Col>          
                        </Form>
                    </div>
                    {showAlert && !fileUploading ? 
                        <AlertMessage alert={alertType} message={showMessage} />
                    :
                        <></>}
                </div>
            </div>
        </>
    )
}

export default ExcelForm