import React, {useState} from "react";
import { Modal, Row, Col, Button, Form} from "react-bootstrap";
import AlertMessage from "../errors/alert";

export default function UserForm(props){
    const options = [
        {
          label: "Administrador",
          value: "1",
        },
        {
          label: "Usuario Normal",
          value: "0",
        }
    ]
    
    return(
        <>
            <Col md="auto" className="mb-3 mb-md-0">
                <Row className="g-3 gy-md-0 h-100 align-items-center">
                    <Col xs="auto" lg="4">
                        Correo: 
                    </Col>
                    <Col md="auto" lg="8">
                        <Form.Group>
                            <Form.Control
                                name="email"
                                onChange={props.handleFieldChange}
                                value={props.formData.email}
                                type="email"
                                maxLength={100}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>  
            <div className='space' style={{"padding": "1vh"}}></div>
            <Col md="auto" className="mb-3 mb-md-0">
                <Row className="g-3 gy-md-0 h-100 align-items-center">
                    <Col xs="auto" lg="4">
                        Nombre: 
                    </Col>
                    <Col md="auto" lg="8">
                        <Form.Group>
                            <Form.Control
                                name="username"
                                onChange={props.handleFieldChange}
                                value={props.formData.username}
                                type="text"
                                maxLength={100}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col> 
            <div className='space' style={{"padding": "1vh"}}></div>
            <Col md="auto" className="mb-3 mb-md-0">
                <Row className="g-3 gy-md-0 h-100 align-items-center">
                    <Col xs="auto" lg="4">
                        Contraseña:
                    </Col>
                    <Col md="auto" lg="8">
                        <Form.Group>
                            <Form.Control
                                name="password"
                                onChange={props.handleFieldChange}
                                value={props.formData.password}
                                type="password"
                                maxLength={100}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col> 
            <div className='space' style={{"padding": "1vh"}}></div>
            <Col md="auto" className="mb-3 mb-md-0">
                <Row className="g-3 gy-md-0 h-100 align-items-center">
                    <Col xs="auto" lg="4">
                        Confirmar Contraseña:
                    </Col>
                    <Col md="auto" lg="8">
                        <Form.Group>
                            <Form.Control
                                name="confirm"
                                onChange={props.handleFieldChange}
                                value={props.formData.confirm}
                                type="password"
                                maxLength={100}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col> 
            <div className='space' style={{"padding": "1vh"}}></div>
            <Col md="auto" className="mb-3 mb-md-0">
                <Row className="g-3 gy-md-0 h-100 align-items-center">
                    <Col xs="auto" lg="4">
                        Tipo de Usuario:
                    </Col>
                    <Col md="auto" lg="8">
                        <Form.Group>
                            <select className="form-select" name="admin" value={props.formData.admin} onChange={props.handleFieldChange}>
                                {options.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </Form.Group>
                    </Col>
                </Row>
            </Col> 
            {props.showAlert ? 
                <AlertMessage alert={"danger"} message={props.showMessage} />
            :
                <></>}
        </>
    )
}