import axios from "axios";
import { useEffect, useState } from 'react';
import Greetings from '../components/dashboard/Greetings';
import "../components/common/status.css"
import Table from '../components/common/Table';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HideModal from "../components/modals/HideModal";
import UnhideModal from "../components/modals/UnhideModal";
import formatNumberStr from "../methods/formatting";

import 'moment-timezone';
import * as moment from "moment";
import DetailModal from "../components/modals/DetailModal";

function Dashboard() {
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [isAdmin, setAdmin] = useState(false)

    const [hideShow, setHideShow] = useState(false)
    const [hideId, setHideId] = useState(0)
    const [enableShow, setEnableShow] = useState(false)
    const [enableId, setEnableId] = useState(0)
    const [showAll, setShowAll] = useState(false)

    const [start, setStart] = useState(translateDate(new Date().setDate(1)))
    const [finish, setFinish] = useState(translateDate(new Date()))

    const [detailShow, setDetailShow] = useState(false);
    const [showDetailId, setShowDetailId] = useState(0)

    const columns = [
        {
            accessor: 'v',
            Header: ' '
        },
        {
            accessor: 'Nombre',
            Header: 'Nombre'
        },
        {
            accessor: 'Compañia',
            Header: 'Compañía'
        },
        {
            accessor: 'Estado',
            Header: 'Estado'
        },
        {
            accessor: 'Última actualización',
            Header: 'Última actualización'
        },
        {
            accessor: 'EC',
            Header: 'EC'
        },
        {
            accessor: 'Energía generada (kW/h)',
            Header: 'Energía generada (kW/h)'
        },
        {
            accessor: 'Costo energía generada',
            Header: 'Facturación ($)'
        },
        {
            accessor: 'Energía esperada (kW/h)',
            Header: 'Energía esperada (kW/h)'
        },
        {
            accessor: 'Costo energía esperada',
            Header: 'Facturación esperada ($)'
        },
        {
            accessor: '% de cumplimiento',
            Header: 'Cumplimiento (%)'
        },
        {
            accessor: 'Diferencia de precio',
            Header: 'Diferencia precio ($)'
        }
    ];

    function timestampToDate(timestamp){
        let date = new Date();
        if(timestamp){
            date = new Date(timestamp * 1000)
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            let hour = date.getHours();
            let minutes = date.getMinutes();

            let currentDate = `${day}/${month}/${year}-${formatTime(hour)}:${formatTime(minutes)}`;
            return currentDate;
        }
        return "Planta sin info"
    }

    function formatTime(component){
        if (parseInt(component) < 10){
            return "0" + component
        }
        return component
    }

    function showDetailOfPlant(id = "0"){
        setShowDetailId(id)
        setDetailShow(true)
    }
      
    function createTooltip(){
        return (
            <Tooltip id="tooltip">
                <strong>Ver detalle de la carga</strong>
            </Tooltip>
        );
    }

    function getStatusColor(status, isEc = false, id = "0"){
        if (status === 3){
            if(isEc){
                return <>
                    <OverlayTrigger placement="top" overlay={createTooltip()}>
                        <div onClick={() => {showDetailOfPlant(id)}}>
                            <span style={{"display": "none"}}>1</span>
                            <span className="failing-dot" ></span>
                        </div>
                    </OverlayTrigger>
                </>
            }
            return <>
                <div>
                    <span style={{"display": "none"}}>1</span>
                    <span className="failing-dot" ></span>
                </div>
            </>
        }
        else if (status === 2){
            if(isEc){
                return <>
                    <OverlayTrigger placement="top" overlay={createTooltip()}>
                        <div onClick={() => {showDetailOfPlant(id)}}>
                            <span style={{"display": "none"}}>3</span>
                            <span className="good-dot" ></span>
                        </div>
                    </OverlayTrigger>
                </>
            }
            return <>
                <div>
                    <span style={{"display": "none"}}>3</span>
                    <span className="good-dot" ></span>
                </div>
            </>
        }
        else if (status === 0){
            if(isEc){
                return <>
                    <OverlayTrigger placement="top" overlay={createTooltip()}>
                        <div onClick={() => {showDetailOfPlant(id)}}>
                            <span style={{"display": "none"}}>1</span>
                            <span className="danger-dot" ></span>
                        </div>
                    </OverlayTrigger>
                </>
            }
            return <>
                <div>
                    <span style={{"display": "none"}}>0</span>
                    <span className="danger-dot" ></span>
                </div>
            </>
        }
        if(isEc){
            return <>
                <OverlayTrigger placement="top" overlay={createTooltip()}>
                    <div onClick={() => {showDetailOfPlant(id)}}>
                        <span style={{"display": "none"}}>2</span>
                        <span className="ewarning-dot" ></span>
                    </div>
                </OverlayTrigger>
            </>
        }
        return <>
                <div>
                    <span style={{"display": "none"}}>2</span>
                    <span className="ewarning-dot" ></span>
                </div>
            </>
    }

    function getECStatus(charge, lastDate, id){
        if (lastDate == "Planta sin info"){
            return getStatusColor(0, true, id)
        }
        let currentMoment = moment().tz("America/Santiago").unix()
        let dateTimestamp = moment(charge, "YYYY-MM-DD HH:mm:ss").tz("America/Santiago").unix()
        const diffTime = currentMoment - dateTimestamp;
        const difference = diffTime / (60 * 60); 
        if (difference >= 24 || isNaN(difference)){
            return getStatusColor(0, true, id)
        }
        if (difference >= 6){
            return getStatusColor(3, true, id)
        }
        if (difference >= 2){
            return getStatusColor(1, true, id)
        }
        return getStatusColor(2, true, id)
    }
    
    function getCompletedColor(completed, type = "?"){
        if(type == "%"){
            if (completed < 85){
                return <span style={{"color": "red"}}>{formatNumberStr(completed, 2)}</span>
            }
            if (completed < 95){
                return <span style={{"color": "rgb(255, 192, 0)"}}>{formatNumberStr(completed, 2)}</span>
            }
            return <span style={{"color": "green"}}>{formatNumberStr(completed, 2)}</span>
        }
        else if(type == "N"){
            if (completed < 0){
                return <span style={{"color": "red"}}>{formatNumberStr(completed, 0)}</span>
            }
            return formatNumberStr(completed, 0, true)
        }
        return completed
    }

    function translateDate(dateN){
        let date = new Date(dateN)
        if (date.getMonth() < 9 && date.getDate() < 10){
            return date.getFullYear().toString() + "0" + (date.getMonth() + 1).toString() + "0" + date.getDate().toString()
        }else if (date.getMonth() < 9){
            return date.getFullYear().toString() + "0" + (date.getMonth() + 1).toString() + date.getDate().toString()
        }
        else if (date.getDate() < 10){
            return date.getFullYear().toString() + (date.getMonth() + 1).toString() + "0" + date.getDate().toString()
        }
        return date.getFullYear().toString() + (date.getMonth() + 1).toString() + date.getDate().toString()
    }

    const handleHide = event => {
        let id = event.currentTarget.id.substring(3);
        setHideId(id)
        setHideShow(true)
    }

    const handleEnable = event => {
        let id = event.currentTarget.id.substring(3);
        setEnableId(id)
        setEnableShow(true)
    }

    function handleClose(){
        setHideShow(false)
        setHideId(0)
        setEnableShow(false)
        setEnableId(0)
    }

    function hiddenButton(hidden, id){
        if(hidden){
            return <Button id={"HB/" + id} onClick={handleEnable} size="sm" variant="danger">
                    <FontAwesomeIcon icon="fa-eye" />
                </Button>
        }
        return <Button id={"HB/" + id} onClick={handleHide} size="sm" variant="primary">
                    <FontAwesomeIcon icon="fa-eye" />
                </Button>
    }

    function apiRequest(startDate = start, endDate = finish, all=showAll){
        setShowAll(all)
        setLoaded(false)
        if(startDate == null || endDate == null){
            startDate = start
            endDate = finish
        }
        setStart(startDate)
        setFinish(endDate)
        const url = process.env.REACT_APP_URL_BACK + `/plant/summary/start/${startDate}/end/${endDate}`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then((response) => {
            if (response.data == null || response.data == ''){
                setData(null)
                setLoaded(true)
                return
            }
            let tableData = response.data
            let dataResult = []
            let offset = moment().tz("America/Santiago").utcOffset() / 60
            for (const tIndex in tableData){
                let tableIndex = tableData[tIndex]
                let energyPrice = Math.round(tableIndex.generatedprice)
                let expectedPrice = Math.round(tableIndex.expectedprice)
                let differencePrice = energyPrice - expectedPrice
                let lastDate = timestampToDate(tableIndex.last)
                let lastChargeDate = moment(tableIndex.last_charge).tz("America/Santiago").format("YYYY-MM-DD HH:mm:ss")
                let dataIndex = {
                        "v": hiddenButton(tableIndex.hidden, tableIndex.id),
                        "Nombre": tableIndex.plantname,
                        "Compañia": tableIndex.company,
                        "Estado": getStatusColor(tableIndex.status),
                        "Última actualización": lastDate,
                        "EC": getStatusColor(tableIndex.ec_status, true, tableIndex.id),
                        "Energía generada (kW/h)": formatNumberStr(tableIndex.energia,2),
                        "Costo energía generada": formatNumberStr(energyPrice,0),
                        "Energía esperada (kW/h)": formatNumberStr(tableIndex.expected,2),
                        "Costo energía esperada": formatNumberStr(expectedPrice,0),
                        "% de cumplimiento": getCompletedColor(tableIndex.cumplimiento, "%"),
                        "Diferencia de precio": getCompletedColor(differencePrice, "N")
                }
                if(all || (!all && !tableIndex.hidden)){
                    dataResult.push(dataIndex)
                }
            }
            setData(dataResult)
            setLoaded(true)
        }).catch((error) => {
            setData([])
            setLoaded(true)
        })
    }

    useEffect(() => {
        let url = process.env.REACT_APP_URL_BACK + `/users/me`
        const config = {
            headers: { Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`}
        };
        axios.get(url,config).then((response) => {
            let userData = response.data
            setAdmin(userData.admin)
        })
        apiRequest()
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='space' style={{"padding": "3vh"}}></div>
            <Greetings updateData={apiRequest}/>
            <DetailModal show={detailShow} showId={showDetailId}
                        handleClose={() => setDetailShow(false)}
                        dateFrom={start} dateTo={finish}/>
            <Table loaded={loaded} 
                   data={data} 
                   columns={
                        isAdmin ? 
                            columns
                        :
                            columns.slice(1)
                   }
                   tabIndex={4} 
                   title="Vista general"
                   canCreate={false}
            />
            <HideModal show={hideShow}
                       showId={hideId}
                       refresh={apiRequest}
                       handleClose={handleClose}/>
            <UnhideModal show={enableShow}
                         showId={enableId}
                         refresh={apiRequest}
                         handleClose={handleClose}/>
        </>
    );
}

export default Dashboard