import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import Main from './Main';
import './helpers/helpers';
import { AuthProvider } from './context/JWTContext';

const root=ReactDOM.createRoot(document.getElementById('main'));
root.render(
  <React.StrictMode>
    <Main>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Main>
  </React.StrictMode>,
);
